import axios from "axios";
import Vue from "vue";
import { TYPE } from "vue-toastification";
import { ERROR_TYPE } from "@/constants/error";
import { CommonApiMessage } from "@/constants/messages";
import { LocalStorage } from "@/constants/enum";

const jobApi = axios.create({
  baseURL: process.env.VUE_APP_JOB_API_URL,
  timeoutErrorMessage: CommonApiMessage.TIMEOUT_ERROR_MESSAGE,
});

const memberApi = axios.create({
  baseURL: process.env.VUE_APP_MEMBER_API_URL,
  timeoutErrorMessage: CommonApiMessage.TIMEOUT_ERROR_MESSAGE,
});

const configureApiHeaders = (config) => {
  const token = localStorage.getItem(LocalStorage.TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.common.Accept = "Application/json";
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
};

const configureApiData = (config) => {
  const memberId = localStorage.getItem(LocalStorage.MEMBER_ID);
  if (memberId) {
    config.data = {
      memberId: memberId,
      ...config.data,
    };
  }
  return config;
};

jobApi.interceptors.request.use(configureApiHeaders);
jobApi.interceptors.request.use(configureApiData);

jobApi.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => {
    Vue.$toast(`${ERROR_TYPE.CONNECTION} ${err}`, {
      timeout: 4000,
      type: TYPE.ERROR,
    });
    return Promise.reject(err);
  }
);

memberApi.interceptors.request.use(configureApiHeaders);
memberApi.interceptors.request.use(configureApiData);

memberApi.interceptors.response.use(
  (res) => Promise.resolve(res),
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
    Vue.$toast(`${ERROR_TYPE.CONNECTION} ${error}`, {
      timeout: 4000,
      type: TYPE.ERROR,
    });
    return Promise.reject(error);
  }
);

export { jobApi, memberApi };
