import { jobApi } from "@/config";
import { Country } from "@/constants/enum";

export default class BillboardService {
  static createJob(payload) {
    return jobApi
      .post("/Job/CreateJob", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getJobList(payload) {
    return jobApi
      .post("/Job/GetJobList", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getMyJobList(payload) {
    return jobApi
      .post("/Job/GetMyJobList", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getCityList(countryId = Country.TURKEY) {
    return jobApi.post(`/Type/GetCityList?countryId=${countryId}`);
  }

  static getTypeList(payload) {
    return jobApi
      .post(`/Type/GetTypeList?parentId=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getJobTypes() {
    return jobApi
      .post(`/Type/GetAllJobTypes`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getCourtHouseList() {
    return jobApi
      .post(`/Type/GetCourthouseList`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.table(err);
      });
  }

  static getCourtHouseByCityId(cityId) {
    return jobApi
      .post(`/Type/GetCourthouseByCityId?cityId=${cityId}`)
      .then((res) => res.data)
      .catch((err) => console.log("err :>> ", err));
  }

  static sendAppyJob(payload) {
    return jobApi
      .post("/Job/ApplyJob", payload)
      .then((res) => res)
      .catch((err) => console.log("err", err));
  }

  static getUserJobCount() {
    return jobApi
      .post("/Job/GetMyJobCount")
      .then((res) => res)
      .catch((err) => console.log("err", err));
  }

  static getUnApprovedJobList() {
    return jobApi
      .get("/Job/GetUnApprovedJobList")
      .then((res) => res.data)
      .catch((err) => console.log("err >> ", err));
  }

  static approveJob(payload) {
    return jobApi
      .post("/Job/ApproveJob", payload)
      .then((res) => res.data)
      .catch((err) => console.log("err >> ", err));
  }

  static assignJob(payload) {
    return jobApi
      .post("/Job/AssignmentJob", payload)
      .then((res) => res.data)
      .catch((err) => console.log("err :>> ", err));
  }

  static getMinValue(subjectId) {
    return jobApi
      .post(`/Type/GetTypeList?parentId=${subjectId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.table(err);
      });
  }
}
