export const ApiMemberMessages = Object.freeze({
  REGISTER_ERROR: "Kayıt sırasında bir hata oluştu.",
  REGISTER_SUCCESS: "Kayıt Başarılı.",
  LOGIS_SUCCESS: "Giriş Başarılı",
  DELETE_EMPLOYEE_SUCCESS: "Üyeliğiniz Silindi",
  GENERAL_ERROR: "Bir hata oluştu.",
});

export const CommonApiMessage = Object.freeze({
  TIMEOUT_ERROR_MESSAGE: "İstek zaman aşımına uğradı, lütfen tekrar deneyiniz.",
});

export const ApiJobMessages = Object.freeze({
  JOB_APPLY_SUCCESS: "Başvurunuz başarıyla iletildi.",
  JOB_APPLY_ERROR: "Başvurunuz iletilirken bir sorunla karşılaşıldı",
  JOB_CREATE_SUCCESS:
    "İlan başarıyla oluşturuldu. İlan onaylandıktan sonra ana sayfada görünecektir.",
});
