import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { LocalStorage } from "@/constants/enum";

Vue.use(VueRouter);

const routes = [
	{
		path: "/giris-yap",
		name: "Login",
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
	},
	{
		path: "/sifremi-unuttum",
		name: "ForgotPassword",
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/auth/ForgotPassword.vue"),
	},
	{
		path: "/sifre-belirle",
		name: "ResetPassword",
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "reset-password" */ "@/views/auth/ResetPassword.vue"),
	},
	{
		path: "/uye-ol",
		name: "BenchInformation",
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "bench-information" */ "@/views/auth/BenchInformation.vue"),
	},
	{
		path: "/baro-bilgilerim",
		name: "BenchInformationDetail",
		props: {
			breadcrumb: "Baro Bilgileriniz",
			pageTitle: "Baro Bilgileri Giriş",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () =>
			import(/* webpackChunkName: "bench-information-detail" */ "@/views/auth/BenchInformationDetail.vue"),
	},
	{
		path: "/kullanim-sozlesmesi",
		name: "UsageAgreement",
		props: {
			breadcrumb: "Kullanım Sözleşmesi",
			pageTitle: "Kullanım Sözleşmesi",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "usage-agreement" */ "@/views/UsageAgreement.vue"),
	},
	{
		path: "/profilim",
		name: "ProfilePage",
		props: {
			breadcrumb: "Profilim",
			pageTitle: "Profilim",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "profile-page" */ "@/views/profile/index.vue"),
	},
	{
		path: "/",
		name: "Billboard",
		props: {
			breadcrumb: "İlan Panosu",
			pageTitle: "İlan Panosu",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "billboard-page" */ "@/views/billboard/index.vue"),
	},
	{
		path: "/ilan-ver",
		name: "BillboardCreate",
		props: {
			breadcrumb: "İlan Ver",
			pageTitle: "İlan Ver",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "billboard-create" */ "@/views/billboard/Create.vue"),
	},
	{
		path: "/mevzuat",
		name: "Legislation",
		props: {
			breadcrumb: "Mevzuat",
			pageTitle: "Mevzuat",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "legislation-create" */ "@/views/legislation/index.vue"),
	},
	{
		path: "/ayarlar",
		name: "SettingsPage",
		props: {
			breadcrumb: "Ayarlar",
			pageTitle: "Ayarlar",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "settings-page" */ "@/views/settings/index.vue"),
	},
	{
		path: "/ayarlar/abonelik-islemleri",
		name: "Subscription",
		props: {
			breadcrumb: "Ayarlar / Abonelik İşlemleri",
			pageTitle: "Abonelik İşlemleri",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "subscription" */ "@/views/settings/Subscription.vue"),
	},
	{
		path: "/ayarlar/islem-gecmisi",
		name: "History",
		props: {
			breadcrumb: "Ayarlar / Ödeme Geçmişi",
			pageTitle: "Ödeme Geçmişi",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "history" */ "@/views/settings/History.vue"),
	},
	{
		path: "/ayarlar/bildirimler",
		name: "Notification",
		props: {
			breadcrumb: "Ayarlar / Bildirimler",
			pageTitle: "Bildirimler",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "notification" */ "@/views/settings/Notification.vue"),
	},
	{
		path: "/ayarlar/bildirim-ayarlari",
		name: "NotificationSettings",
		props: {
			breadcrumb: "Ayarlar / Görev Almak İstediğim Adliyeler",
			pageTitle: "Görev Almak İstediğim Adliyeler",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "notification" */ "@/views/settings/NotificationSettings.vue"),
	},
	{
		path: "/ayarlar/iletisim",
		name: "ContactPage",
		props: {
			breadcrumb: "Ayarlar / İletişim",
			pageTitle: "İletişim",
		},
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "contact" */ "@/views/settings/Contact.vue"),
	},
	{
		path: "/component-list",
		name: "ComponentList",
		meta: {
			layout: () => import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
		},
		component: () => import(/* webpackChunkName: "component-list" */ "@/views/ComponentList.vue"),
	},
	{
		path: "/mesajlar",
		name: "MessageList",
		props: {
			breadcrumb: "Mesajlar",
			pageTitle: "Mesajlar",
		},
		meta: {
			layout: () => import("@/layouts/MainLayout.vue"),
		},
		component: () => import("@/views/message/index.vue"),
	},
	{
		path: "/odeme/basarili",
		name: "PaymentSuccess",
		props: {
			pageTitle: "Ödeme",
		},
		meta: {
			layout: () => import("@/layouts/MainLayout.vue"),
		},
		component: () => import("@/views/payment/success/index.vue"),
	},
	{
		path: "/odeme/hata",
		name: "PaymentError",
		props: {
			pageTitle: "Ödeme",
		},
		meta: {
			layout: () => import("@/layouts/MainLayout.vue"),
		},
		component: () => import("@/views/payment/error/index.vue"),
	},
	{
		path: "/admin/onay-bekleyenler",
		name: "ApproveJobs",
		props: {
			pageTitle: "Onay Bekleyen Ilanlar",
		},
		meta: {
			layout: () => import("@/layouts/MainLayout.vue"),
		},
		component: () => import("@/views/profile/JobsToApprove.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = [
		"/giris-yap",
		"/uye-ol",
		"/sifremi-unuttum",
		"/baro-bilgilerim",
		"/kullanim-sozlesmesi",
		"/sifre-belirle",
	];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem(LocalStorage.TOKEN);

	if (loggedIn) {
		store.commit("setIsAuth", true);
		store.dispatch("getNotifications");

		if (to.path === "/giris-yap") {
			window.location.href = "/";
		}
	}

	if (authRequired && !loggedIn) {
		return next("/giris-yap");
	}

	next();
});

export default router;
