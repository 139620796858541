export const Parent = Object.freeze({
  JOBTYPE: "47CEE489-D3AA-4720-917C-8ADB624C3374",
  PRICE: "10ca3fda-4cde-40c5-967e-e2f48aeef88e",
  JOB_APPLY_TYPE: "44e1a370-e3cf-4c4a-8fb4-5d3fa339d363",
  LEGISLATION: "15c66ccf-629c-47bd-901d-b1037823668d",
  CREDIT_YEAR: "4374B0F1-044D-413D-9AB1-2D1755D65286",
  CREDIT_MONTH: "2DD2EFEB-CA7F-4839-9647-3DA8EBED0F40",
  COUTRHOUSE_TYPE: "70e84031-50ef-40ea-902f-4a639d06c91f",
});

export const Country = Object.freeze({
  TURKEY: "14364D36-C4F1-41B3-9391-2E57BB41E424",
});

export const Filter = Object.freeze({
  DECREASING: "380d44ec-6a68-4067-82dd-88bffed22145",
  INCREASING: "18D7FAE8-9D57-45A9-ABF3-1E5252E8B689",
  OLDEST_TO_NEWEST: "B0EFB6A3-FCB3-4D7D-A5E7-6F9A48D604C2",
  NEWEST_TO_OLDEST: "D26949C7-A37A-4522-AC6C-FD62EC5F96B0",
});

export const LocalStorage = Object.freeze({
  TOKEN: "token",
  MEMBER_ID: "memberId",
});

export const JobApplyType = Object.freeze({
  CAN_TAKE_CARE: "8DEA2601-ADFA-4DE2-8F5E-4AD6797B585E",
  AT_COURTHOUSE: "1E764E6B-F0C8-44D8-B4D6-B9380A27F3AF",
  WITHIN_THE_DAY: "00D66D45-7862-4C7B-8100-E977BA592BB8",
});

export const ContentType = Object.freeze({
  KVKK: "b1faa60b-2efb-4a01-9215-9e2369dbbe8d",
  USER_AGREEMENT: "5487efb4-5d7e-42f0-a660-4b961b226f31",
  SELLING_CONTRACT: "A0E89AB5-3926-4349-9CA7-109AF79560D9",
  PRE_INFORMATION: "B1FE7793-E0B2-4BF0-9654-A9A18A789105",
});

export const CourtHouseTypeEnum = Object.freeze({
  IN_COURT_HOUSE: "2206095a-0f62-41ac-846c-1b6c41a5dd2e",
  OUTSIDE_COURT_HOUSE: "4690744c-64af-4ea6-abb0-73d8a3f09a52",
});
