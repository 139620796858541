import { memberApi } from "@/config";

export default class MessageService {
	static getMessageList() {
		return memberApi
			.post("/Message/GetMessageList")
			.then((res) => res.data)
			.catch((err) => {
				console.log(err);
			});
	}
	static sendMessage(payload) {
		return memberApi
			.post("/Message/SendMessage", payload)
			.then((res) => res.data)
			.catch((err) => {
				console.log(err);
			});
	}
	static getMessageDetail(messageMemberId) {
		return memberApi
			.post(`/Message/MessageDetail?messageMemberId=${messageMemberId}`)
			.then((res) => res.data)
			.catch((err) => {
				console.log(err);
			});
	}

	static deleteMessage(messageId) {
		return memberApi
			.post(`/Message/DeleteMessage?messageMemberId=${messageId}`)
			.then((res) => res.data)
			.catch((err) => console.log("err :>> ", err));
	}

	static checkNewNotification() {
		return memberApi
			.get("/Notification/CheckNotification")
			.then((res) => res.data)
			.catch((err) => console.log("err :>> ", err));
	}

	static getNotifications() {
		return memberApi
			.get("/Notification/GetNotificationList")
			.then((res) => res.data)
			.catch((err) => console.log("err :>> ", err));
	}

	static setNotificationSeen() {
		return memberApi
			.post("/Notification/SetSeenNotification")
			.then((res) => res.data)
			.catch((err) => console.log("err :>> ", err));
	}

	static getMemberNotificationSettingsList() {
		return memberApi
			.get("/Notification/GetMemberNotificationSettingsList")
			.then((res) => res.data)
			.catch((err) => console.log(err));
	}

	static updateMemberNotificationSettings(settings) {
		return memberApi
			.post("Notification/UpdateMemberNotificationSettings", settings)
			.then((res) => res.data)
			.catch((err) => console.log(err));
	}
}
