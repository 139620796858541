import { memberApi } from "@/config";

export default class AuthService {
  static login(payload) {
    return memberApi
      .post("/Auth/Login", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  static createEmployee(payload) {
    return memberApi
      .post("/Auth/CreateEmployee", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  static deleteEmployee() {
    return memberApi
      .post("/Auth/DeleteEmployee")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  static forgetPassword(payload) {
    return memberApi
      .post("/Auth/ForgetPassword", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  static changePassword(payload) {
    return memberApi
      .post("/Auth/SetPassword", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static fetchUserDetail(payload) {
    return memberApi
      .post("/Auth/GetEmployee", payload)
      .then((res) => res.data)
      .catch((err) => console.table(err));
  }

  static checkPackageDay() {
    return memberApi
      .get("/MemberPackage/CheckPackageDay")
      .then((res) => res.data)
      .catch((err) => console.log("err :>> ", err));
  }
}
