import { TYPE } from "vue-toastification";
/* import { ERROR_TYPE } from "@/constants/error";*/
import AuthService from "@/services/auth.service";
import router from "@/router";
import Vue from "vue";
import { ApiMemberMessages } from "@/constants/messages";
import { LocalStorage } from "@/constants/enum";
import MessageService from "@/services/message.service";

const state = {
  isAuth: false,
  memberId: localStorage.getItem(LocalStorage.MEMBER_ID) || undefined,
  registerUser: {},
  userDetail: {},
  newNotification: false,
};

const getters = {
  getIsAuth: (state) => state.isAuth,
  getUserDetail: (state) => state.userDetail,
  getRegisterUser: (state) => state.registerUser,
  getMemberId: (state) => state.memberId,
  getIsUserSuperAdmin: (state) => state.userDetail.isSuperAdmin,
  getNewNotification: (state) => state.newNotification,
};

const mutations = {
  setIsAuth: (state, status) => {
    state.isAuth = status;
  },
  setUserDetail: (state, payload) => {
    state.userDetail = payload;
  },
  setRegisterUser: (state, payload) => {
    state.registerUser = { ...state.registerUser, ...payload };
  },
  setIsTCUndefined: (state) => {
    state.registerUser.isTC = undefined;
  },
  setMemberId: (state, payload) => {
    state.memberId = payload;
  },
  clearStore: (state) => {
    state.isAuth = false;
    state.memberId = undefined;
    state.registerUser = {};
    state.userDetail = {};
  },
  setNewNotification: (state, payload) => {
    state.newNotification = payload;
  },
};

const actions = {
  async login(_, payload) {
    const response = await AuthService.login(payload);
    if (response.status) {
      localStorage.setItem(LocalStorage.TOKEN, response.data.token);
      localStorage.setItem(LocalStorage.MEMBER_ID, response.data.id);
      Vue.$toast(ApiMemberMessages.LOGIS_SUCCESS, {
        timeout: 2000,
        type: TYPE.SUCCESS,
      });
      window.location.href = "/";
    } else {
      Vue.$toast(response.message, {
        timeout: 4000,
        type: TYPE.ERROR,
      });
    }
  },

  logout({ commit }) {
    localStorage.clear();
    commit("clearStore");
    router.push({ name: "Login" });
  },
  deleteEmployee({ commit }) {
    const toast = {};
    AuthService.deleteEmployee()
      .then((response) => {
        if (response.status) {
          toast.message = ApiMemberMessages.DELETE_EMPLOYEE_SUCCESS;
          toast.type = TYPE.SUCCESS;
        } else {
          toast.message = response.message;
          toast.type = TYPE.ERROR;
        }
        Vue.$toast(toast.message, {
          timeout: 4000,
          type: toast.type,
        });

        localStorage.clear();
        commit("clearStore");
        router.push({ name: "Login" });
      })
      .catch((error) => {
        console.log(error);
        toast.message = ApiMemberMessages.GENERAL_ERROR;
        toast.type = TYPE.ERROR;
      });
  },
  createEmployee({ commit }, payload) {
    const toast = {};
    AuthService.createEmployee(payload)
      .then((response) => {
        if (response.status) {
          toast.message = ApiMemberMessages.REGISTER_SUCCESS;
          toast.type = TYPE.SUCCESS;
          router.push({ name: "Login" });
          commit("clearStore");
        } else {
          toast.message = response.message;
          toast.type = TYPE.ERROR;
        }
        Vue.$toast(toast.message, {
          timeout: 4000,
          type: toast.type,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.message = ApiMemberMessages.REGISTER_ERROR;
        toast.type = TYPE.ERROR;
      });
  },
  // eslint-disable-next-line no-empty-pattern
  forgetPassword({}, payload) {
    AuthService.forgetPassword(payload)
      .then((response) => {
        Vue.$toast(response.message, {
          timeout: 4000,
          type: response.status ? TYPE.SUCCESS : TYPE.ERROR,
        });
        if (response.status) {
          return true;
        }
        return false;
      })
      .catch((error) => console.log(error));
  },
  // eslint-disable-next-line no-empty-pattern
  changePassword({}, payload) {
    AuthService.changePassword(payload)
      .then((response) => {
        const messageOptions = {
          timeout: 4000,
          type: TYPE.SUCCESS,
        };

        if (!response.status) {
          messageOptions.type = TYPE.ERROR;
        }

        Vue.$toast(response.message, messageOptions);
        router.push({ name: "Login" });
      })
      .catch((error) => console.log(error));
  },

  fetchUserDetail({ state, commit }) {
    if (Object.keys(state.userDetail).length <= 0) {
      AuthService.fetchUserDetail({
        memberId: state.memberId,
      }).then((res) => {
        commit("setUserDetail", res.data);
      });
    }
  },

  async getNotifications({ commit }) {
    const response = await MessageService.checkNewNotification();
    if (response && response.status) {
      commit("setNewNotification", response.data);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
