const state = {
  packageInfo: {
    selectedPackage: undefined,
    address: undefined,
    city: undefined,
  },
};

const getters = {
  getPackageInfo: (state) => state.packageInfo,
};

const mutations = {
  setPackageInfo: (state, payload) => {
    state.packageInfo = payload;
  },
};

const actions = {
  selectPackage({ commit }, payload) {
    commit("setPackageInfo", payload);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
