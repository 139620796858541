import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueScreen from 'vue-screen';
import router from './router';
import store from './store';
import Toast, { POSITION } from "vue-toastification";
import Vuelidate from 'vuelidate';
Vue.use(require('vue-moment'));

import lang from "element-ui/lib/locale/lang/tr-TR";
import locale from "element-ui/lib/locale";
import 'element-ui/lib/theme-chalk/index.css';
import "vue-toastification/dist/index.css";
import "./assets/css/main.css";

locale.use(lang);

const options = {
  position: POSITION.TOP_RIGHT
};

Vue.use(ElementUI);
Vue.use(Vuelidate);
Vue.use(Toast, options);
Vue.use(VueScreen, {
  desktop: 1000
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
