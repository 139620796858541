<template>
  <div id="app">
    <component :is="$route.meta.layout">
      <router-view class="router-view" />
    </component>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapActions(["fetchCities"]),
  },
  async created() {
    await this.fetchCities();
  },
};
</script>

<style>
body {
  background-size: cover;
  background: linear-gradient(148.57deg, #003767 23.82%, #075090 85.89%);
}
#app {
  @apply flex
  flex-col
  min-h-screen
  justify-between;
}
.el-radio {
  @apply !mx-0;
}
.el-radio .el-radio__inner {
  @apply h-[13px] w-[13px] rounded-full border-none bg-lawyer-alto;
}

.el-radio__input.is-checked .el-radio__inner {
  @apply bg-white;
}

.el-radio__input.is-checked .el-radio__inner:after {
  @apply bg-lawyer-green h-[13px] w-[13px] transition-none;
}

.el-radio.box-radio {
  @apply bg-white w-[300px] lg:w-[300px] text-lawyer-ebony-clay font-semibold;
}
.el-radio.box-radio .el-radio__input.is-checked + .el-radio__label {
  @apply text-lawyer-ebony-clay;
}

.el-radio__input.is-checked + .el-radio__label {
  @apply font-semibold text-lawyer-nevada;
}
</style>
