import { memberApi } from "@/config";

export default class PaymentService {
  static getToken(payload) {
    return memberApi
      .post("/Payment/GetToken", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static getPackageList() {
    return memberApi
      .get("Payment/GetPackageList")
      .then((res) => res.data)
      .catch((err) => console.log(err));
  }
}
