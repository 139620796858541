import Vue from "vue";
import { TYPE } from "vue-toastification";
import { ApiJobMessages } from "@/constants/messages";
import BillboardService from "@/services/billboard.service";
import PaymentService from "@/services/payment.service";

const state = {
  billboardList: [],
  billboardPayload: {},
  jobApplyTypes: [],
  courtHouseType: [],
  searchFilter: {
    memberId: undefined,
    cityId: undefined,
    jobTypeId: [],
    courthouseId: undefined,
  },
  cities: [],
  packageList: [],
};

const getters = {
  getBilboardList: (state) => state.billboardList,
  getBilboardPayload: (state) => state.billboardPayload,
  getJobApplyTypes: (state) => state.jobApplyTypes,
  getCities: (state) => state.cities,
  getSearchFilter: (state) => state.searchFilter,
  getCourtHouseTypes: (state) => state.courtHouseType,
  getCityDetail: (state) => (id) => {
    return state.cities.find((data) => data.id === id);
  },
  getPackageList: (state) => {
    return state.packageList;
  },
};

const mutations = {
  addNewBillboard: (state, payload) => {
    state.billboardList.push(payload);
  },
  setJobList: (state, payload) => {
    state.billboardList = payload;
  },
  setBillboardPayload(state, payload) {
    state.billboardPayload = { ...state.billboardPayload, ...payload };
  },
  setjobApplyTypes(state, payload) {
    state.jobApplyTypes = payload;
  },
  setCourtHouseTypes(state, payload) {
    state.courtHouseType = payload;
  },
  setSearchFilter(state, payload) {
    state.searchFilter = payload;
  },
  setCities: (state, payload) => {
    state.cities = payload;
  },
  setBillboardApplied(state, payload) {
    const billboard = state.billboardList.findIndex(
      (bb) => bb.jobId === payload
    );
    if (billboard > -1) {
      state.billboardList[billboard].canApply = false;
    }
  },
  setPackageList: (state, payload) => {
    state.packageList = payload;
  },
};

const actions = {
  async saveBillboard(_, payload) {
    const response = await BillboardService.createJob(payload);
    if (response.status) {
      Vue.$toast(ApiJobMessages.JOB_CREATE_SUCCESS, {
        timeout: 2000,
        type: TYPE.SUCCESS,
      });
    }
  },
  getTypeList({ commit, state }, payload) {
    const type = payload.type;
    let isFetch = true;
    switch (type) {
      case "jobApply":
        if (state.jobApplyTypes.length > 0) {
          isFetch = false;
        }
        break;
      case "courtHouseType":
        if (state.courtHouseType.length > 0) {
          isFetch = false;
        }
        break;
      default:
        break;
    }
    if (isFetch) {
      return BillboardService.getTypeList(payload.parentId)
        .then((response) => {
          switch (type) {
            case "jobApply":
              commit("setjobApplyTypes", response.data);
              break;
            case "courtHouseType":
              commit("setCourtHouseTypes", response.data);
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  getCourtHouseList({ commit }) {
    return BillboardService.getCourtHouseList()
      .then((response) => {
        commit("setCourtHouseList", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getJobList({ commit, state }) {
    const filter = state.searchFilter;
    return BillboardService.getJobList(filter)
      .then((response) => {
        if (response.status) {
          commit("setJobList", response.data);
        } else {
          commit("setJobList", []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setSearchFilter({ commit }, payload) {
    commit("setSearchFilter", payload);
  },
  fetchCities: async ({ commit }, payload) => {
    const { data } = await BillboardService.getCityList(payload);
    commit("setCities", data);
  },
  billboardApplied: ({ commit }, payload) => {
    commit("setBillboardApplied", payload);
  },
  fetchPackageList: async ({ commit, state }) => {
    if (state.packageList && state.packageList.length > 0) {
      return;
    }
    const payload = await PaymentService.getPackageList();
    if (payload) {
      commit("setPackageList", payload);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
