import { memberApi } from "@/config";

export default class ContentService {
  static sendContactMessage(payload) {
    return memberApi
      .post("/Content/SendContactMessage", payload)
      .then((res) => res.data)
      .catch((err) => {
        console.info(err);
      });
  }

  static getContent(payload) {
    return memberApi
      .post("/Content/GetContent", payload)
      .then((res) => res.data)
      .catch((err) => {
        console.log("err", err);
      });
  }
}
