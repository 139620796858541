import ContentService from "@/services/content.service";

const state = {
  isKvkkFetched: false,
  isUserAgreementFetched: false,
  isPreInformationFetched: false,
  isSellingContractFetched: false,
  kvkk: {
    text: "",
    title: "",
  },
  userAgreement: {
    text: "",
    title: "",
  },
  preInformation: {
    text: "",
    title: "",
  },
  sellingContract: {
    text: "",
    title: "",
  },
};

const getters = {
  getKvkk: (state) => state.kvkk,
  getUserAgreement: (state) => state.userAgreement,
  getPreInformation: (state) => state.preInformation,
  getSellingContract: (state) => state.sellingContract,
};

const mutations = {
  setKvkk: (state, payload) => {
    state.isKvkkFetched = true;
    state.kvkk = payload;
  },
  setUserAgreement: (state, payload) => {
    state.isUserAgreementFetched = true;
    state.userAgreement = payload;
  },
  setPreInformation: (state, payload) => {
    state.isPreInformationFetched = true;
    state.preInformation = payload;
  },
  setSellingContract: (state, payload) => {
    state.isSellingContractFetched = true;
    state.sellingContract = payload;
  },
};

const actions = {
  async fetchContent({ commit, state }, payload) {
    const type = payload.type;
    let isFetch = true;
    switch (type) {
      case "kvkk":
        if (state.isKvkkFetched) {
          isFetch = false;
        }
        break;
      case "userAgreement":
        if (state.isUserAgreementFetched) {
          isFetch = false;
        }
        break;
      case "preInformation":
        if (state.isPreInformationFetched) {
          isFetch = false;
        }
        break;
      case "sellingContract":
        if (state.isSellingContractFetched) {
          isFetch = false;
        }
        break;
    }

    if (isFetch) {
      const data = {
        id: payload.id,
      };
      return ContentService.getContent(data)
        .then((response) => {
          switch (type) {
            case "kvkk":
              commit("setKvkk", response.data);
              break;
            case "userAgreement":
              commit("setUserAgreement", response.data);
              break;
            case "preInformation":
              commit("setPreInformation", response.data);
              break;
            case "sellingContract":
              commit("setSellingContract", response.data);
              break;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
