import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import billboard from "./modules/billboard";
import settings from "./modules/settings";
import content from "./modules/content";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    billboard,
    settings,
    content,
  },
});
